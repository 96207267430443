import React from 'react'

const getData = (data,student) => {
	var objData = "N/A"
	const selectedObjData = data.map(value => (value[student] < 2) && <li key={value['Objective Label']}>{value['Objective Label']}</li>)
	const selectedObjDataChecker = selectedObjData.filter(value => value !== false)
	if(selectedObjDataChecker.length >= 1){
		objData = selectedObjData
	}

	return objData
}

const getObjData = (objectiveData,subject,selectedTerm,selectedStudent) => {
	var termData = "N/A"

	const selectedTermData = objectiveData.filter(value => (value['subjName'] === (subject+"-"+selectedTerm) || value['subjName'] === (subject+" -"+selectedTerm)))
							.map(value => {
								const data = value['data'].map(value => <li key={value['objTitle']}><b>{value['objTitle']}</b><br/><ul>{getData(value['objData'],selectedStudent)}</ul></li>)
								return data
							})
	if(selectedTermData.length >= 1){
		termData = selectedTermData
	}

	return termData
}

function IndividualSummary(props) {
	const {data} = props
	const {objectiveData,selectedTerm,selectedStudent} = data

	return (
		<div className="is-container">
			<div className="container">
				<div className="row mb-3">
					<div className="col-12">
						<div className="wrapper-label text-center"><h5 className="upper font-weight-bold text-white p-1 bg-info">Instructions:</h5></div>
						<div className="instruction-wrapper"><p>This report tells you which objectives that particular child has scored 1 or 0 in from the objectives covered.</p></div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-4 mb-5">
						<div className="wrapper-label text-center"><h5 className="upper font-weight-bold text-white p-1 bg-danger">Mathematics</h5></div>
						<ul>{getObjData(objectiveData,"Mathematics",selectedTerm,selectedStudent)}</ul>
					</div>
					<div className="col-lg-4 col-4 mb-5">
						<div className="wrapper-label text-center"><h5 className="upper font-weight-bold text-white p-1 bg-warning">Reading</h5></div>
						<ul>{getObjData(objectiveData,"Reading",selectedTerm,selectedStudent)}</ul>
					</div>
					<div className="col-lg-4 col-4 mb-5">
						<div className="wrapper-label text-center"><h5 className="upper font-weight-bold text-white p-1 bg-primary">Writing</h5></div>
						<ul>{getObjData(objectiveData,"Writing",selectedTerm,selectedStudent)}</ul>
					</div>
				</div>
			</div>
		</div>
	)
}

export default IndividualSummary