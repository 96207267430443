import React from 'react'
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";

function ProcessLine(props) {
	return (

		<Progress strokeWidth={1} percent={props.grade} status={props.status}
		theme={{
		    below: {
		      symbol: props.grade,
		      trailColor: 'rgb(228, 228, 228)',
		      color: '#ed3024'
		    },
		    workingtowards: {
		      symbol: props.grade,
		      trailColor: 'rgb(228, 228, 228)',
		      color: '#fbc630'
		    },
		    secure: {
		      symbol: props.grade,
		      trailColor: 'rgb(228, 228, 228)',
		      color: '#449331'
		    },
		    exceeding: {
		      symbol: props.grade,
		      trailColor: 'rgb(228, 228, 228)',
		      color: '#0055a3'
		    },
		  }}
		/>

		/*<Progress strokeWidth={1} percent={props.grade} status={props.status}
		theme={{
		    basic: {
		      symbol: props.grade,
		      trailColor: 'rgb(228, 228, 228)',
		      color: '#efefef'
		    },
		    below: {
		      symbol: props.grade,
		      trailColor: 'rgb(228, 228, 228)',
		      color: '#9e9e9e'
		    },
		    emerging: {
		      symbol: props.grade,
		      trailColor: 'rgb(228, 228, 228)',
		      color: '#fbc630'
		    },
		    developing: {
		      symbol: props.grade,
		      trailColor: 'rgb(228, 228, 228)',
		      color: '#e28619'
		    },
		    secure: {
		      symbol: props.grade,
		      trailColor: 'rgb(228, 228, 228)',
		      color: '#449331'
		    },
		    exceeding: {
		      symbol: props.grade,
		      trailColor: 'rgb(228, 228, 228)',
		      color: '#8c1d94'
		    },
		  }}
		/>*/
	)
}

export default ProcessLine