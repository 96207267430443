import React from 'react'
import {Pie} from 'react-chartjs-2';

function ProcessChart(props) {

	const ext = (props.type==="percent") ? "%":""
	const statusData = {
		datasets: [{
			data: [
				//props.basic,
				props.below,
				//props.emerging,
				//props.developing,
				props.workingtowards,
				props.secure,
				props.exceeding
			],
			backgroundColor: [
				//'#efefef',
			    '#ed3024',
			    //'#fbc630',
			    //'#e28619',
			    '#fbc630',
			    '#449331',
			    '#0055a3'
			],
			label: props.termName // for legend
		}],
		labels: [
			//props.basic+ext,
			props.below+ext,
			//props.emerging+ext,
			//props.developing+ext,
			props.workingtowards+ext,
			props.secure+ext,
			props.exceeding+ext
		]
	}

	const legendOpts = {
		display: true,
		position: 'right',
		fullWidth: true,
		reverse: false,
		labels: {
			fontColor: '#111111'
		}
	}
	return (
		<div>
			<p className="upper mb-3">{props.termName}</p>
			<Pie data={statusData} legend={legendOpts} />
		</div>
	)
}

export default ProcessChart