import React from 'react'

const getObjData = (subjectData,subject,selectedTerm) => {
	const arr = []
	var rankData = "N/A";
	const selectedTermData = subjectData.filter(value => (value['subjName'] === (subject+"-"+selectedTerm) || value['subjName'] === (subject+" -"+selectedTerm)))
							.map((value,index) => value['subjData'])
	console.log(subjectData);
	if(selectedTermData.length >= 1){
		selectedTermData[0].map(value => {
			if(Object.keys(value).length !== 1 && value['Objective Label'].includes('Label') === false) {
				const dataValue = Object.values(value).splice(1,Object.values(value).length)
				const dataValueTotal = dataValue.reduce((current,prev) => current+prev)
				arr.push({
					name: value['Objective Label'],
					value: dataValueTotal
				})
			}
			return true
		})

		arr.sort(function(a,b) {
		    return a.value - b.value;
		});

		rankData = arr.splice(0,10).map(value => <li key={value['name']}>{value['name']}</li>)
	}
	return rankData
	
}

function ClassSummary(props) {

	const {data} = props
	const {subjectData,selectedTerm} = data

	return (
		<div className="cs-container">
			<div className="container">
				<div className="row mb-3">
					<div className="col-12">
						<div className="wrapper-label text-center"><h5 className="upper font-weight-bold text-white p-1 bg-info">Instructions:</h5></div>
						<div className="instruction-wrapper"><p>This report tells you which 10 objectives your class has made the least progress in; therefore giving you the opportunity to cater for them with interventions or whole class of teaching of the explicit objective.</p></div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-4 mb-5">
						<div className="wrapper-label text-center"><h5 className="upper font-weight-bold text-white p-1 bg-danger">Mathematics</h5></div>
						<ol>{getObjData(subjectData,"Mathematics",selectedTerm)}</ol>
					</div>
					<div className="col-lg-4 col-4 mb-5">
						<div className="wrapper-label text-center"><h5 className="upper font-weight-bold text-white p-1 bg-warning">Reading</h5></div>
						<ol>{getObjData(subjectData,"Reading",selectedTerm)}</ol>
					</div>
					<div className="col-lg-4 col-4 mb-5">
						<div className="wrapper-label text-center"><h5 className="upper font-weight-bold text-white p-1 bg-primary">Writing</h5></div>
						<ol>{getObjData(subjectData,"Writing",selectedTerm)}</ol>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ClassSummary