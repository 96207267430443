import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import {SheetJSFT} from './types'
//import { Fabric } from 'office-ui-fabric-react/lib/Fabric'
//import { DefaultButton } from 'office-ui-fabric-react/lib/Button'
import XLSX from 'xlsx'
//import { make_cols } from './MakeColumns'
import { calculateProgressData,
         getObjNumberPerStudent,
         calculateGradeData,
         getRangeData,
         getRangeData2,
         calculateStatus } from './CalculateData'
import LockIn from './LockIn'
import IndividualSummary from './IndividualSummary'
import ClassSummary from './ClassSummary'
import Logo from '../assets/img/dbs-logo.svg'
import KeyboardEventHandler from 'react-keyboard-event-handler';



class ExcelReader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      file: {},
      subjectList: [],
      termList: [],
      classDetails: [],
      studentNumber: "",
      studentName: [],
      subjectData: [],
      objectiveData: [],
      selectedSubject: "All",
      selectedPage: "Lock In",
      selectedTerm: "",
      selectedStudent: "",
      load: "preventLoad",
      loaded: "",
      sidebar: "",
      printStatus: ""
    }

    this.handleFile = this.handleFile.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSelection = this.handleSelection.bind(this)
    this.getEventTarget = this.getEventTarget.bind(this)
    this.filterAction = this.filterAction.bind(this)
    this.printAction = this.printAction.bind(this)
    this.printActionKey = this.printActionKey.bind(this)
  }
 

  handleSelection(e){
    const {name, value} = e.target
      if(name === "page") {
        this.setState({
            load: "preventLoad",
          },()=> {
            setTimeout(()=>{
              this.setState({
                selectedPage : value,
                load: "",
              })
            },500)
          })
      } else if(name === "subject"){
          this.setState({
            load: "preventLoad",
          },()=> {
            setTimeout(()=>{
              this.setState({
                selectedSubject: value,
                load: "",
              })
            },500)
          })
      } else if(name === "term"){
          this.setState({
            load: "preventLoad",
          },()=> {
            setTimeout(()=>{
              this.setState({
                selectedTerm : value,
                load: "",
              })
            },500)
          })
      } else if(name === "student"){
          this.setState({
            load: "preventLoad",
          },()=> {
            setTimeout(()=>{
              this.setState({
                selectedStudent : value,
                load: "",
              })
            },500)
          })
      }
  }

  componentDidMount(){
    setTimeout(()=>{
      this.setState({
        loaded: "loaded"
      })
    },500)
  }

  handleChange(e) {
    //const files = e.target.files
    const files = e
    if ((files && files[0]) && files[0]['type'] === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      this.setState({ file: files[0]})
      this.handleFile()
    } else {
      alert("Please select the correct file!")
    }

    //console.log(files[0]['type'])
    //this.handleFile()
  }
 
  handleFile() {
    /* Boilerplate to set up FileReader */
      const reader = new FileReader()
      const rABS = !!reader.readAsBinaryString
      
      reader.onload = (e) => {
        /* Parse data */
        const subjData = []
        const subjList = []
        const termList = []
        const bstr = e.target.result
        const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA : true, cellDates:true })
        const sheetTabs = wb.SheetNames
        /* Get first worksheet */
        //console.log(wb.SheetNames)
        //const wsname = wb.SheetNames[1]
        //const ws = wb.Sheets[wsname]

        /* Convert array of arrays */
        //const data = XLSX.utils.sheet_to_json(ws)

        // Checking Class Details
        const classDetails = getRangeData(wb,wb.SheetNames[0],2,6)

        // Checking Students
        const studentData = getRangeData(wb,wb.SheetNames[0],0,1)
        const studentName = studentData.map(value => value['First Name'] + " " + value['Surname'])
        
        //const find = studentName[0]
        //const gradeData = data.map(value => (value[find]) ? value[find] : 0)
        //                    .reduce((prev,current) => prev + current)
        
        // Setting Up Data
        for(var i = 0; i < sheetTabs.length; i++) {
          const wsname = sheetTabs[i]
          if(wsname !== "Start") {
            //const ws = wb.Sheets[wsname]
            /* Convert array of arrays */
            const subjectData = getRangeData2(wb,wsname,0,31)
            //const subjectData = XLSX.utils.sheet_to_json(ws)

            subjData.push({
                subjName: wsname,
                subjData: subjectData
            })

            subjList.push(wsname.split("-")[0])
            termList.push(wsname.split("-")[1])

          }
        }

        // Setting Up Objectives Data
        this.setState({ subjectList: [...new Set(subjList)],
                        termList: [...new Set(termList)],
                        classDetails: Object.values(classDetails[0]),
                        studentNumber: studentData.length,
                        studentName: studentName,
                        subjectData: subjData,
                        selectedTerm: [...new Set(termList)][0],
                        selectedStudent: studentName[0]},()=>{
          const subj = this.state.subjectData
          const objectiveData = []

          for(var s = 0; s < subj.length; s++) {
            const d = subj[s].subjData
            const dTitle = subj[s].subjName
            const objTitle = []
            const objData = []
            const objDataComputed = []
            
            // Checking Objective Labels
            for(var i = 0; i < d.length; i++) {
                if(Object.keys(d[i]).length === 1 && d[i]['Objective Label'].includes('Label')) {
                  
                  var currentIndex = d.indexOf(d[i])

                  objTitle.push(currentIndex)
                  //console.log(r.indexOf(r[i]) + " - " + r[i]['Objective Label'])
                }
            }
            
            // Checking Objectives
            for(var x = 0; x < objTitle.length; x++) {
              const start = objTitle[x] + 1
              const e = objTitle[x+1]
              const end = (isNaN(e)) ? d.length : e
              const label = d[objTitle[x]]['Objective Label'].split(" - ")
              objData.push({
                objTitle: label[1],
                objData: d.slice(start,end)
              })
            }

            const objNumber = (objTitle.length) && (d.length - objTitle.length)
            const sn = this.state.studentName

            // Calculating Objectives
            for(var y = 0; y < sn.length; y++) {
              const subjectTotalCount = calculateGradeData(d,sn[y])
              const objCount = getObjNumberPerStudent(d,sn[y])
    

              objDataComputed.push({
                name: sn[y],
                totalGrade: calculateProgressData(subjectTotalCount,objCount),
              })
            }
            
            const ns = this.state.studentNumber
            objectiveData.push({
              subjName: dTitle,
              numOfObj: objNumber,
              data: objData,
              dataComputed: objDataComputed,
              statusData: {
                /*basic:  { 
                          total: calculateStatus(objDataComputed,'basic').length,
                          totalPercentage: (calculateStatus(objDataComputed,'basic').length/ns) * 100
                        },*/
                below:  { 
                          total: calculateStatus(objDataComputed,'below').length,
                          totalPercentage: (calculateStatus(objDataComputed,'below').length/ns) * 100
                        },
                /*emerging: { 
                            total: calculateStatus(objDataComputed,'emerging').length,
                            totalPercentage: (calculateStatus(objDataComputed,'emerging').length/ns) * 100
                          },
                developing: { 
                              total: calculateStatus(objDataComputed,'developing').length,
                              totalPercentage: (calculateStatus(objDataComputed,'developing').length/ns) * 100
                            },*/
                workingtowards: { 
                          total: calculateStatus(objDataComputed,'workingtowards').length,
                          totalPercentage: (calculateStatus(objDataComputed,'workingtowards').length/ns) * 100
                        },
                secure: { 
                          total: calculateStatus(objDataComputed,'secure').length,
                          totalPercentage: (calculateStatus(objDataComputed,'secure').length/ns) * 100
                        },
                exceeding: { 
                          total: calculateStatus(objDataComputed,'exceeding').length,
                          totalPercentage: (calculateStatus(objDataComputed,'exceeding').length/ns) * 100
                        }
              }
            })
            
          }
            
          this.setState({
            objectiveData: objectiveData,
            selectedSubject: "All"
            //selectedSubject: this.state.subjectList[0]
          }, () => {
            //console.log(this.state)
            setTimeout(()=>{
              this.setState({
                load: "",
              })
            },300)
          })

        })
        
        //this.setState({ studentName: studentName, data: data, cols: make_cols(ws['!ref']) }, () => {
          //console.log(this.state.data)
        //})
        
      }
 
      if (rABS) {
        reader.readAsBinaryString(this.state.file)
      } else {
        reader.readAsArrayBuffer(this.state.file)
      }
    
  }

  getEventTarget(e) {
      e = e || window.event
      return e.target || e.srcElement 
  }

  filterAction(e) {
    e.preventDefault()
    const status = (this.state.sidebar) ? "" : "opened"
    this.setState({
      sidebar: status
    })
  }
 
  printAction(e) {
    e.preventDefault()
    this.setState({
      printStatus: "printing"
    },() => {
      window.print()
      this.setState({
        printStatus: ""
      })
    })
  }

  printActionKey(key) {
      //console.log(key)
      this.setState({
        printStatus: "printing"
      }, () => {
        setTimeout(() => {
          this.setState({
            printStatus: ""
          })
        },3000)
      })
  }

  dateConvert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
 
  render() {
    const _this = this.state
    const studentNames = this.state.studentName
    const studentName = studentNames.map(value => {return <li key={value}>{value}</li>})
    const studentNameOption = studentNames.map(value => {return <option value={value}>{value}</option>})
    const termList = _this.termList.map(value => {return <option value={value}>{value}</option>})
    const subjectList = _this.subjectList.map(value => {return <option key={value} value={value}>{value}</option>})
    const subjs = _this.subjectList.map((value,index) => {
                    const c = (index !== 0) ? "lockinmain-container " + _this.printStatus+"Data" : ""
                    return <div key={value} className={c}><br/><LockIn students={_this.studentName} selectedSubject={value} data={_this.objectiveData}/><br/><hr className={_this.printStatus} /><br/></div>
                  })
    const date = this.dateConvert(_this.classDetails[4])
    return (
      <div className={"data-container "+ this.state.loaded}>
        <KeyboardEventHandler
          handleKeys={['ctrl+p', 'meta+p']}
          onKeyEvent={(key, e) => this.printActionKey(key) } />
        {(studentName <= 0) &&
          <div className="container">
              
                <div className="upload-container">
                <Dropzone onDrop={this.handleChange}>
                  {({getRootProps, getInputProps}) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} accept={SheetJSFT}/>
                        <div className="upload-wrapper">
                          <img src={Logo} alt="Logo" width="70px"/>
                          <br />
                          <br />
                          <p>Drag 'n' drop some files here, <br/> or click to select files</p>
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
                </div>
             
          </div>
        }
        { (studentName.length !== 0) &&
          <div className={"btn-container " + _this.printStatus}>
            <a href="#filter" className="open-filter btn-primary btn-fixed" onClick={this.filterAction}><i className="mdi mdi-filter"></i></a>
            <a href="#print" className="btn-print btn-primary btn-fixed" onClick={this.printAction}><i className="mdi mdi-printer"></i></a>
          </div>
        }
        { (studentName.length !== 0) &&
          <div className={"side-container " + _this.sidebar + " " + _this.printStatus}>
              <div>
              <div className="container-title mt-4 mb-4"><h2 className="font-weight-bold upper">Filter By:</h2></div>
              </div>
              <div>
                <div className="row-header">
                  <b>Page:</b>
                    <select className="page-btn" name="page" onChange={this.handleSelection} defaultValue={"Lock In"}>
                      <option value="Lock In">Lock In</option>
                      <option value="Individual Summary">Individual Summary</option>
                      <option value="Class Summary">Class Summary</option>
                    </select>
                </div>
              </div>
              {(_this.selectedPage === "Lock In") &&
                <div>
                  <div className="row-header">
                    <b>Subjects:</b>
                  </div>
                  <select name="subject" className="subject-btn" onChange={this.handleSelection} defaultValue={_this.selectedSubject}>
                  {(subjectList.length !== 1) && <option value="All">All</option>}
                  {subjectList}
                  </select>
                </div>
              }
              {(_this.selectedPage === "Individual Summary" || _this.selectedPage === "Class Summary") &&
              <div>
                <div className="row-header">
                  <b>Term:</b>
                </div>
                <select name="term" className="term-btn" onChange={this.handleSelection} defaultValue={_this.selectedTerm}>
                  {termList}
                </select>
              </div>
              }
              {(_this.selectedPage === "Individual Summary") &&
              <div>
                <div className="row-header">
                  <b>Students:</b>
                </div>
                <select name="student" className="student-btn" onChange={this.handleSelection} defaultValue={_this.selectedStudent}>
                  {studentNameOption}
                </select>
              </div>
              }
          </div>
        }
        <div className="data-wrapper">
        {(studentName.length !== 0 && _this.selectedPage === "Lock In") &&
          <div>
            <div className="header-container pt-3 pb-2">
              <div className="container">
                  <div className="row">
                    <div className="col-lg-3 col-3"><img src={Logo} alt="Logo" width="70px"/></div>
                    <div className="col-lg-7 col-7">
                    <ul>
                      <li><b>Year: {_this.classDetails[0]}</b></li>
                      <li><b>Class Animal: {_this.classDetails[1]}</b></li>
                      <li><b>Teacher: {_this.classDetails[2]}</b></li>
                      <li><b>Academic Year: {_this.classDetails[3]}</b></li>
                    </ul>
                    </div>
                    <div className="col-lg-2 col-2 text-center"><b>Class Total</b><br/>
                    <h1><b>{studentNames.length}</b></h1></div>
                  </div>
              </div>
            </div>
           
            <div className="label-container">
              <div className="container">
                  <div className="row">
                    <div className="col-lg-3 col-2 bg bg-below text-center font-weight-bold bl"><p className="upper">{/*SEN Core Skills*/}Below</p></div>
                    <div className="col-lg-3 col-3 bg bg-workingtowards text-center font-weight-bold"><p className="upper">{/*Towards 2*/}Working Towards</p></div>
                    <div className="col-lg-3 col-2 bg bg-secure text-center font-weight-bold"><p className="upper">{/*Expected*/}Expected</p></div>
                    <div className="col-lg-3 col-2 bg bg-exceeding text-center font-weight-bold br"><p className="upper">Greater Depth</p></div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-2 p-0 m-0 text-center font-weight-bold">
                      <div className="row p-0 m-0">
                        <div className="col-12 p-0 m-0 borderLine"><p className="label-range">0-39%</p></div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-3 p-0 m-0 text-center font-weight-bold">
                      <div className="row p-0 m-0">
                        <div className="col-12 p-0 m-0 borderLine"><p className="label-range">40-59%</p></div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-3 p-0 m-0 text-center font-weight-bold">
                      <div className="row p-0 m-0">
                        <div className="col-12 p-0 m-0 borderLine"><p className="label-range">60-89%</p></div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-2 p-0 m-0 text-center font-weight-bold">
                      <div className="row p-0 m-0">
                          <div className="col-12 p-0 m-0 borderLine"><p className="label-range">90-100%</p></div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            
            <div className="container">
              { (studentName.length !== 0) &&
                <div className={this.state.load}>

                {(_this.selectedSubject === "All") 
                  ?
                  <div>{subjs}</div>
                  :
                  <div><br/><LockIn students={_this.studentName} selectedSubject={_this.selectedSubject} data={_this.objectiveData}/></div>
                }
                </div>
              }
            </div>
          </div>
        }
        {(studentName.length !== 0 && _this.selectedPage === "Individual Summary") &&
          <div>
            <div className="header-container pt-3 pb-2">
              <div className="container">
                  <div className="row">
                    <div className="col-lg-3 col-3"><img src={Logo} alt="Logo" width="70px"/></div>
                    <div className="col-lg-9 col-9">
                    <h2 className="font-weight-bold pb-2">Your Child's Report - {_this.selectedTerm}</h2>
                    <div className="row">
                      <div className="col-4">
                        <p><b>Pupil Name:</b> {_this.selectedStudent}</p>
                        <p><b>Teacher:</b> {_this.classDetails[2]}</p>
                      </div>
                      <div className="col-4">
                        <p><b>Class Animal:</b> {_this.classDetails[1]}</p>
                        <p><b>Year:</b> {_this.classDetails[0]}</p>
                      </div>
                      <div className="col-4">
                        <p><b>Academic Year:</b> {_this.classDetails[3]}</p>
                        <p><b>Date:</b> {date}</p>
                      </div>
                    </div>
                    </div>
                  </div>
              </div>
            </div>
            <br/>
            <IndividualSummary data={_this}/>
          </div>
        }
        {(studentName.length !== 0 && _this.selectedPage === "Class Summary") &&
          <div>
            <div className="header-container pt-3 pb-2">
              <div className="container">
                  <div className="row">
                    <div className="col-lg-3 col-3"><img src={Logo} alt="Logo" width="70px"/></div>
                    <div className="col-lg-9 col-9">
                    <h2 className="font-weight-bold pb-2">What needs working on as a class - {_this.selectedTerm}</h2>
                    <div className="row">
                      <div className="col-4">
                        <p><b>Teacher:</b> {_this.classDetails[2]}</p>
                        <p><b>Class Animal:</b> {_this.classDetails[1]}</p>
                      </div>
                      <div className="col-4">
                        <p><b>Year:</b> {_this.classDetails[0]}</p>
                        <p><b>Academic Year:</b> {_this.classDetails[3]}</p>
                      </div>
                      <div className="col-4">
                        <p><b>Date:</b> {date}</p>
                      </div>
                    </div>
                    </div>
                  </div>
              </div>
            </div>
            <br/>
            <ClassSummary data={_this}/>
          </div>
        }
        </div>
        </div>
    )
  }
}
 
export default ExcelReader

