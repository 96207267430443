import XLSX from 'xlsx';

export const getRangeData = (wb,ws,sr,er) => {
  var range = XLSX.utils.decode_range(wb.Sheets[ws]['!ref']);
  range.s.c = sr; // 0 == XLSX.utils.decode_col("A")
  range.e.c = er; // 1 == XLSX.utils.decode_col("B")
  var new_range = XLSX.utils.encode_range(range);

  return  XLSX.utils.sheet_to_json(wb.Sheets[ws], {blankRows: false, defval: '', range: new_range});
}

export const getRangeData2 = (wb,ws,sr,er) => {
  var range = XLSX.utils.decode_range(wb.Sheets[ws]['!ref']);
  range.s.c = sr; // 0 == XLSX.utils.decode_col("A")
  range.e.c = er; // 1 == XLSX.utils.decode_col("B")
  var new_range = XLSX.utils.encode_range(range);

  return  XLSX.utils.sheet_to_json(wb.Sheets[ws], {range: new_range});
}

//Calculate total grade per student
export const calculateGradeData = (data, name) => {
    const gradeData = data.map(value => (value[name]) ? value[name] : 0)
                          .reduce((prev,current) => prev + current)

    return gradeData
}

//Calculate progress of the total grade per student and objective
export const calculateProgressData = (subjectTotalCount, objCount) => {
	const t = parseInt((subjectTotalCount/(objCount*3)*100).toFixed())
	const total = (isNaN(t)) ? parseInt(0) : t
	return total
}

//Total count of the objective per student
export const getObjNumberPerStudent = (data, find) => {
	return data.map(value => (value[find] || value[find] === 0) ? 1 : 0)
          	   .reduce((prev,current) => prev + current)
}

export const calculateStatus = (data, action) => {
	switch (action) {
		/*case 'basic':
			return data.map(value => value.totalGrade)
					   .filter(current => current === 0)*/
		case 'below':
			return data.map(value => value.totalGrade)
					   .filter(current => current >= 0 && current <= 39)
		case 'workingtowards':
			return data.map(value => value.totalGrade)
					   .filter(current => current >= 40 && current <= 59)

		/*case 'emerging':
			return data.map(value => value.totalGrade)
					   .filter(current => current >= 10 && current <= 39)
		
		case 'developing':
			return data.map(value => value.totalGrade)
					   .filter(current => current >= 40 && current <= 69)*/
		
		case 'secure':
			return data.map(value => value.totalGrade)
					   .filter(current => current >= 60 && current <= 89)
		
		case 'exceeding':
			return data.map(value => value.totalGrade)
					   .filter(current => current >= 90)

		default:
			return 0
	}
}

export const getTotalProgressPerStudent = (data, name) => {

}

export const getStatus = (grade) => {
	var status = ""

	/*if(grade >= 1 && grade <= 9) {
		status = "below"
	} else if(grade >= 10 && grade <= 39) {
		status = "emerging"
	} else if(grade >= 40 && grade <= 69) {
		status = "developing"
	} else if(grade >= 70 && grade <= 89) {
		status = "secure"
	} else if(grade >= 90) {
		status = "exceeding"
	} else if(grade === 0) {
		status = "basic"
	} else {
		status = "basic"
	}*/

	if(grade >= 0 && grade <= 39) {
		status = "below"
	} else if(grade >= 30 && grade <= 59) {
		status = "workingtowards"
	} else if(grade >= 60 && grade <= 89) {
		status = "secure"
	} else if(grade >= 90) {
		status = "exceeding"
	} else {
		status = "below"
	}

	return status
}

