import React from 'react'
import {getStatus} from './CalculateData'
import ProcessLine from './ProcessLine'
import ProcessChart from './ProcessChart'

export class LockIn extends React.Component {

	constructor(props) {
	  super(props);
	
	  this.state = {
	  };
	  this.getGradeByName = this.getGradeByName.bind(this);
	}

	getGradeByName(name,total) {
		const terms = total.map(value => {
			const data = value['studentGrade'].filter(value => value['name'] === name)
			const d = data.map(value => {
				const grade = value['totalGrade']
				var status = getStatus(grade)
				return <li key={name}><ProcessLine grade={grade} status={status}/> </li>
			})
			return d
		})

		return terms
	}

	render() {
		const {data,selectedSubject,students} = this.props
	    //const studentName = students.map(value => {return <li key={value}>{value}</li>})
		const total = []
		const selectedSubjectData = data.filter(value => (value['subjName'].includes(selectedSubject)))
		selectedSubjectData.map((value,index) => {
			const termName = value['subjName'].split('-')[1]
			const statusData = value['statusData']
			const studentGradeFilter = students.map(name => {
				const filterGrade = value['dataComputed'].filter(value => (value['name'] === name))
				return filterGrade[0]
			})


			const studentGrade = studentGradeFilter.map(value => value)
			//console.log(statusData)
			total.push({
				subjName : termName,
				studentGrade : studentGrade,
				statusData: statusData
			})
			
			return true
		})
		//console.log(total)

		const studentList = students.map(value => {
			return <ul key={value}><li>{value}</li>{this.getGradeByName(value,total)}</ul>
		})

		const termList = total.map(value => <li key={value['subjName']}><b>{value['subjName']}</b></li>)

	  	const processGradeStatus = total.map((value,index) => {
			const termName = value['subjName']
			
			//const basic = value['statusData']['basic']['total']
			const below = value['statusData']['below']['total']
			//const emerging = value['statusData']['emerging']['total']
			//const developing = value['statusData']['developing']['total']
			const workingtowards = value['statusData']['workingtowards']['total']
			const secure = value['statusData']['secure']['total']
			const exceeding = value['statusData']['exceeding']['total']

			
			//console.log(studentGrade)
			//return <li key={termName} className="col-lg-4 col-md-4 col-12 font-weight-bold pr-5"><ProcessChart basic={basic} below={below}  emerging={emerging}  developing={developing}  secure={secure}  exceeding={exceeding} termName={termName} /></li>
			return <li key={termName} className="col-lg-4 col-md-4 col-12 font-weight-bold pr-5"><ProcessChart below={below}  workingtowards={workingtowards}  secure={secure}  exceeding={exceeding} termName={termName} /></li>
		})

	  	const processGradePercentageStatus = total.map((value,index) => {
			const termName = value['subjName']
			
			//const basic = value['statusData']['basic']['totalPercentage'].toFixed(0)
			const below = value['statusData']['below']['totalPercentage'].toFixed(0)
			//const emerging = value['statusData']['emerging']['totalPercentage'].toFixed(0)
			//const developing = value['statusData']['developing']['totalPercentage'].toFixed(0)
			const workingtowards = value['statusData']['workingtowards']['totalPercentage'].toFixed(0)
			const secure = value['statusData']['secure']['totalPercentage'].toFixed(0)
			const exceeding = value['statusData']['exceeding']['totalPercentage'].toFixed(0)

			
			//console.log(studentGrade)
			//return <li key={termName} className="col-lg-4 col-md-4 col-12 font-weight-bold pr-5"><ProcessChart basic={basic} below={below}  emerging={emerging}  developing={developing}  secure={secure}  exceeding={exceeding} termName={termName} type="percent"/></li>
			return <li key={termName} className="col-lg-4 col-md-4 col-12 font-weight-bold pr-5"><ProcessChart below={below}  workingtowards={workingtowards} secure={secure}  exceeding={exceeding} termName={termName} type="percent"/></li>
		})

		return (
			<div className="lockin-container mt-2 mb-5">
				<div className="row">
					<div className="col-lg-12 col-12">
		            	<h3 className="font-weight-bold upper">{selectedSubject}</h3>
		            </div>
					<div className="col-lg-12 col-12">
						<ul className="llabel-container"><li>&nbsp;</li>{termList}</ul>
						<div className="student-container">{studentList}</div>
					</div>
					<div className="col-lg-12 col-12"></div>

					<div className="col-lg-12 col-12 text-center mb-1 font-weight-bold">
		            	<h3 className="font-weight-bold upper mt-5 mb-1">Total Score</h3>
		            	<ul className="legend-container mb-3">
			            	<li className="bg bg-below"><p>Below</p></li>
			            	<li className="bg bg-workingtowards"><p>Working Towards</p></li>
			            	<li className="bg bg-secure"><p>Expected</p></li>
			            	<li className="bg bg-exceeding"><p>Greate Depth</p></li>
		            	</ul>
		            	<ul className="row">{processGradeStatus}</ul>
		            </div>

					<div className="col-lg-12 col-12 text-center font-weight-bold">
		            	<h3 className="font-weight-bold upper mt-5 mb-1">Total Score Percentage</h3>
		            	<ul className="legend-container mb-3">
			            	<li className="bg bg-below"><p>Below</p></li>
			            	<li className="bg bg-workingtowards"><p>Working Towards</p></li>
			            	<li className="bg bg-secure"><p>Expected</p></li>
			            	<li className="bg bg-exceeding"><p>Greate Depth</p></li>
		            	</ul>
						<ul className="row">{processGradePercentageStatus}</ul>
		            </div>
				</div>
			</div>
		)
	}
}

export default LockIn